//swiper js
import Swiper from "swiper/bundle";
//swiper css
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

jQuery(function ($) {
    //product swiper (must be before lightbox) change to horizontal to vertical for direction of slider
    if ($(".productSwiper").length) {
        var productSwiper = new Swiper(".productSwiper", {
            watchOverflow: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            allowTouchMove: true,
            preventInteractionOnTransition: true,
            breakpoints: {
                0: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    direction: "horizontal",
                },
                1280: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                    direction: "horizontal",
                },
                1400: {
                    slidesPerView: 5,
                    spaceBetween: 24,
                    direction: "horizontal",
                },
            },
        });
    }

    if ($(".productSwiper2").length) {
        var productSwiper2 = new Swiper(".productSwiper2", {
            // allowTouchMove: false,
            watchOverflow: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preventInteractionOnTransition: true,
            navigation: {
                nextEl: ".product-swiper-button-next",
                prevEl: ".product-swiper-button-prev",
            },
            pagination: {
                el: ".product-swiper-pagination",
                type: "bullets",
            },
            effect: "slide",
            thumbs: {
                swiper: productSwiper,
            },
            breakpoints: {
                0: {
                    allowTouchMove: true,
                    direction: "horizontal",
                },
                1280: {
                    allowTouchMove: false,
                    direction: "horizontal",
                },
            },
        });
    }

    function getMonthName(monthNumber) {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return months[parseInt(monthNumber, 10) - 1];
    }

    //full desk anchor link
    $("body").on("click", "#anchorLink", function (event) {
        event.preventDefault();

        var targetClass = ".fulldescription_anchor";

        // Find the target element with the specified class
        var $targetElement = $(targetClass);

        // Calculate the top position of the target element
        var targetTop = $targetElement.offset().top;

        // Animate scrolling to the top of the target element
        $("html, body").animate({ scrollTop: targetTop }, 200);
    });

    //jquery update price based on variant selection

    $(".single_variation_wrap").on(
        "show_variation",
        function (event, variation) {
            console.log(variation);

            var bundled = variation.is_bundled;

            var price = variation.price_html;
            if (price == "") {
                // var currencySymbol = woocommerce_currency_symbol;
                var price =
                    site_data.currency +
                    Number(variation.display_price).toFixed(2); // Dan - Modified to ensure two decimal places
            }
            var was = variation.display_regular_price.toFixed(2);
            var is_in_stock = variation.is_in_stock;

            var imagesrcset = variation.image.srcset;
            var imagesrc = variation.image.full_src;
            var imageurl = variation.image.url;
            var imagesizes = variation.image.sizes;
            var imagealt = variation.image.alt;

            var img =
                '<img class="" src="' +
                imagesrc +
                '" srcset="' +
                imagesrcset +
                '" sizes="' +
                imagesizes +
                '" alt="' +
                imagealt +
                '">';

            // console.log(img);

            // $(".saleMessage").hide();
            // $(".salepriceSpan").hide();
            // $(".salepriceSymbol").hide();

            if (was) {
                if (was != price) {
                    $(".saleMessage").show();
                    $(".salepriceSpan").show();
                    $(".salepriceSymbol").show();
                    $(".salepriceSpan").html(was);
                }
            }

            if (!bundled) {
                if ($(".ajaxImageReplace").length) {
                    $(".ajaxImageReplace").attr("href", imageurl);
                    $(".ajaxImageReplace").html(img);

                    if ($(".productSwiper2").length) {
                        productSwiper2.slideTo(0);
                    }
                }
            }

            var metaArray = [];

            // Push each variable into the array - NOTE DO NOT CHANGE ORDER OF FIRST 4
            metaArray.push(
                variation.sku,
                variation.cm_hsb,
                variation.cm_coo,
                variation.weight_html,
                //variation.cm_barcode,
                variation.cm_actual_colour,
                variation.cm_size,
                variation.cm_model,
                variation.cm_product_type,
                variation.cm_product_weight,
                variation.cm_gender,
                variation.cm_age,
                variation.cm_sport
            );

            var html = '<ul class="specListUl">';
            var bottomHtml = '<ul class="bottomListUl">';

            metaArray.forEach(function (item, index) {
                if (item) {
                    var itemValue = item.replace(/<strong>.*?<\/strong>/g, "");

                    var tempDiv = $("<div/>").html(item);
                    var strongTags = tempDiv.find("strong");
                    //get text from strong tags
                    var contentInStrongTagsTemp = strongTags.text();
                }
                if (index > 3) {
                    if (item) {
                        //strip :
                        var contentInStrongTags =
                            contentInStrongTagsTemp.replace(/:/g, "");
                        html +=
                            '<li class="d-flex justify-content-between"><strong>' +
                            contentInStrongTags +
                            '</strong><div class="valueContainer"><span class="value">' +
                            itemValue +
                            "</span></div></li>";
                    }
                }
                if (index < 4) {
                    var contentInStrongTags = contentInStrongTagsTemp;
                    if (index == 0) {
                        bottomHtml +=
                            '<li class="">SKU: <span class="value">' +
                            itemValue +
                            '</span><span class="slash">/</span></li>';
                    }
                    if (index != 0 && index != 3) {
                        if (item) {
                            bottomHtml +=
                                '<li class="">' +
                                contentInStrongTags +
                                '<span class="value">' +
                                itemValue +
                                '</span><span class="slash">/</span></li>';
                        }
                    }
                    if (index == 3) {
                        bottomHtml +=
                            '<li class="">C.Wt: <span class="value">' +
                            itemValue +
                            "</span></li>";
                    }
                }
            });

            html += "</ul>";
            bottomHtml += "</ul>";

            //generate box
            if (!bundled) {
                $(".specList .ULresponse").html(html + bottomHtml);
                $(".specList").show();

                $(".priceSummary span").html(price);
            }

            var inStock =
                '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><g id="Group_351" data-name="Group 351" transform="translate(0.5 0.5)"><circle id="Ellipse_44" data-name="Ellipse 44" cx="12.5" cy="12.5" r="12.5" fill="none" stroke="#669C8F" stroke-miterlimit="10" stroke-width="1"/><path id="Path_237" data-name="Path 237" d="M59.056,263.477l4.545,4.544,8.765-8.765" transform="translate(-53.633 -251.139)" fill="none" stroke="#669C8F" stroke-miterlimit="10" stroke-width="1"/></g></svg>';

            if (is_in_stock == false) {
                var eta = variation.cm_eta;

                // Check if eta is not set
                if (!eta) {
                    $(".stock").html('<p class="ms-1">Out of Stock</p>');
                } else {
                    eta = eta.replace(/<strong>.*?<\/strong>/g, "");
                    var dateParts = eta.split("-");
                    var year = dateParts[0];
                    var month = dateParts[1];
                    var day = dateParts[2];
                    var formattedDate = getMonthName(month) + year;
                    $(".stock").html(
                        '<p class="ms-1">Out of Stock - Due in ' +
                            formattedDate +
                            "</p>"
                    );
                }
            } else {
                $(".stock").html(inStock + '<p class="ms-1">In Stock</p>');
            }

            // if(variation.availability_html){
            //     $('.stock').html(variation.availability_html)
            // }else{
            // 	$('.stock').html(inStock+'<p class="ms-1">In Stock</p>')
            // }
        }
    );

    //apply coupon ajax on checkout
    $("body").on("click", "#checkout_apply_coupon", function (event) {
        event.preventDefault();

        var code = $("#checkout_coupon_code").val();
        console.log(code);

        var button = $(this);

        button.val("Applying.");

        $.ajax({
            type: "POST",
            url: site_data.adminajax,
            data: {
                action: "coupon",
                coupon_code: code,
            },
        })
            .done(function (response) {
                setTimeout(function () {
                    //reload with ajax
                    $(document.body).trigger("update_checkout");
                    button.val("Apply Coupon");
                }, 2000);
            })
            .fail(function () {})
            .always(function () {});
    });

    //cart plus and minus
    $(document).on("click", "button.cartplus, button.cartminus", function () {
        var qty = $(this).parent(".quantity").find(".qty");
        var val = parseFloat(qty.val());
        var max = parseFloat(qty.attr("max"));
        var min = parseFloat(qty.attr("min"));
        var step = parseFloat(qty.attr("step"));

        if ($(this).is(".cartplus")) {
            if (max && max <= val) {
                qty.val(max).change();
            } else {
                qty.val(val + step).change();
            }
        } else {
            if (min && min >= val) {
                qty.val(min).change();
            } else if (val > 1) {
                qty.val(val - step).change();
            }
        }

        $("[name='update_cart']").trigger("click");
    });

    $(".woocommerce-MyAccount-navigation li.is-active").click(function (e) {
        if ($("li.is-active").is(e.target)) {
            $(this).parent().toggleClass("open");
        }
    });

    function billinhandshipping() {
        $("#shipping_email").focusout(function () {
            $("#billing_email").val($("#shipping_email").val());
        });

        $("#shipping_phone").focusout(function () {
            $("#billing_phone").val($("#shipping_phone").val());
        });

        $(
            ".woocommerce form.woocommerce-checkout .shipping_address :input"
        ).each(function () {
            var shippingFieldID = $(this).attr("id");

            $("#" + shippingFieldID).focusout(function () {
                var BillingFieldID = $(this)
                    .attr("id")
                    .replace("shipping", "billing");
                if ($("#bill-to-different-address-checkbox").is(":checked")) {
                    if (
                        shippingFieldID == "shipping_state" ||
                        shippingFieldID == "shipping_country"
                    ) {
                        $("#" + BillingFieldID)
                            .val($("#" + BillingFieldID).val())
                            .change();
                    } else {
                        $("#" + BillingFieldID).val(
                            $("#" + BillingFieldID).val()
                        );
                    }
                } else {
                    if (shippingFieldID == "shipping_state") {
                        $("#" + BillingFieldID)
                            .val($(this).val())
                            .change();
                    } else if (shippingFieldID == "shipping_country") {
                        var billVal = $("#" + BillingFieldID).val();
                        var shipVal = $("#" + shippingFieldID).val();
                        if (shipVal != billVal) {
                            $("#" + BillingFieldID)
                                .val($(this).val())
                                .change();
                        }
                    } else {
                        $("#" + BillingFieldID).val($(this).val());
                    }
                }
            });
        });
    }

    billinhandshipping();

    $("body").on("change", "#bill-to-different-address-checkbox", function () {
        if (this.checked) {
            billinhandshipping();
            $("#ship-to-different-address-checkbox").prop("checked", true);
            $(".woocommerce-billing-fields").slideDown();
            $("#billing_first_name").val("");
            $("#billing_last_name").val("");
            $("#billing_address_1").val("");
            $("#billing_address_2").val("");
            $("#billing_city").val("");
            $("#billing_state").val("").change();
            $("#billing_postcode").val("");
            $("#billing_country").val("").change();
            $("#billing_company").val("");
            // $("#billing_email").val('');
        } else {
            billinhandshipping();
            $("#ship-to-different-address-checkbox").prop("checked", false);
            $(".woocommerce-billing-fields").slideUp();
            $("#billing_first_name").val($("#shipping_first_name").val());
            $("#billing_last_name").val($("#shipping_last_name").val());
            $("#billing_address_1").val($("#shipping_address_1").val());
            $("#billing_address_2").val($("#shipping_address_2").val());
            $("#billing_city").val($("#shipping_city").val());
            $("#billing_state").val($("#shipping_state").val()).change();
            $("#billing_postcode").val($("#shipping_postcode").val());
            $("#billing_country").val($("#shipping_country").val()).change();
            $("#billing_company").val($("#shipping_company").val());
            // $("#billing_email").val($("#shipping_email").val());
        }
    });
});
